import { useState } from 'react';

export const useShopifyOrder = () => {
  const [state, setState] = useState({
    response: null,
    loading: false,
    error: null,
  });

  const getOrderByName = async (query: { orderName: string}) => {
    setState({ response: null, loading: true, error: null });

    const data = await fetch('/.netlify/functions/order', {
      method: 'POST',
      body: JSON.stringify({ orderName: query.orderName }),
    });

    const res = await data.json();

    if (res.error) {
      setState({
        response: null,
        loading: false,
        error: res.error,
      });
    } else {
      setState({
        response: res,
        loading: false,
        error: null,
      });
    }
  };

  return [getOrderByName, state] as const;
};
