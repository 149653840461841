import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import ShopifyBuy from 'shopify-buy';

import {
  color, desktopVW, minTablet, mobileVW,
} from '../styles';

import { Cta } from '../components';
import { Button } from '../shared/Button';
import { Typography } from '../shared/Typography';
import { Card, UpgradeSelectors } from '../components/configurator';
import { useShopifyOrder } from '../hooks/shop/useShopifyOrder';
import { InputField } from '../shared/Inputs';

const VergetenPage = () => {
  const data = useStaticQuery(graphql`
    query allProducts {
      allShopifyProduct {
        edges {
          node {
            id
            handle
            storefrontId
            title
            shopifyId
            variants {
              title
              price
              storefrontId
            }
            priceRangeV2 {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
          }
        }
      }
    }
  `);

  const [cart, setCart] = useState<ShopifyBuy.Cart>();

  const client = ShopifyBuy.buildClient({
    storefrontAccessToken: 'f15d844837e9a0b4592863b6c71e3e9c',
    domain: 'rgbdisco.myshopify.com',
  });

  const [getOrderByName, state] = useShopifyOrder();

  const [orderId, setOrderId] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const getNumberOutOfString = (string : string) => string.replace(/^\D+/g, '');

  const handleClick = () => {
    getOrderByName({ orderName: `name:${orderId}` });
  };

  useEffect(() => {
    // @ts-ignore
    setSelectedProducts(products.map(({
      handle, title, quantity, variants,
    } : any) => ({
      handle, title, quantity, variant: variants.filter((obj : { title: string }) => Number(obj.title) === parseInt(totalDays || '', 10))[0],
    })));
  }, [products]);

  async function getNewCart() {
    const newCart = await client.checkout.create();
    setCart(newCart);
  }

  useEffect(() => {
    getNewCart();
  }, [!cart]);

  const handleCheckOut = async () => {
    const items:any = selectedProducts.map((item:any) => ({ variantId: item.variant.storefrontId, quantity: item.quantity, customAttributes: { key: 'Pakket uitbreiding op', value: orderId } }));

    const newCart = await client.checkout.addLineItems(
      cart?.id || '',
      items as ShopifyBuy.LineItem[],
    );
    setCart(newCart);
    // @ts-ignore buggy in shopufy buy
    window.location.href = cart?.webUrl || '';
  };

  // @ts-ignore
  const mainProduct:any = state.response && state.response.orders.edges[0].node.lineItems.edges.filter((lineItem:any) => lineItem.node.name.includes('RGB koptelefoon'))[0];
  const totalDays = mainProduct ? getNumberOutOfString(mainProduct.node.name) : null;

  return (
    <StyledCart>
      <StyledTitle size="is-xl" variants="h2">
        Bestelling incompleet?
      </StyledTitle>
      <Cta
        title="Is je bestelling incompleet? Geen probleem! Bestel hier extra's."
        column1="Voeg je extra's toe en vul je ordernummer in."
        column2="Let op dit is alleen een optie als je bestelling nog niet verzonden is. "
      />
      <Background>
        <Card title="Vul je order id in" description="* Check je mail voor je bestelnummer" secondaryTitle={totalDays && `${totalDays} dagen`}>
          <StyledIdSelector>
            <InputField value={orderId} onChange={(e:any) => setOrderId(e.target.value)} title="Order nummer" />
            <Button className="button-vergeten" handleClick={() => handleClick()} type="button" size="is-sm">
              {state.loading ? 'Aan het zoeken...' : 'Zoek order'}
            </Button>
          </StyledIdSelector>
        </Card>

        <Card key="Selecteer je vergeten items" description="Onverwachts met meer man? Geen probleem!" title="Selecteer je vergeten items">
          <StyledIdSelector>
            {state.response ? (
              <>
                <UpgradeSelectors totalDays={5} getProducts={products} setProducts={setProducts} customProductArr={data.allShopifyProduct.edges} />
                <Button className="button-vergeten" handleClick={() => handleCheckOut()} type="button" size="is-sm">
                  Direct bijbestellen
                </Button>
              </>
            ) : 'Vul eerst je order nummer in om alle mogelijkheden te zien.'}
          </StyledIdSelector>
        </Card>
      </Background>
    </StyledCart>
  );
};

const StyledCart = styled.div`
  
`;

const StyledTitle = styled(Typography)`
  margin: ${desktopVW(60)};
`;

const StyledIdSelector = styled.div`
  margin-top: ${mobileVW(30)};
  position: relative;

  .button-vergeten {
    display: block;
    margin-top: ${mobileVW(15)};
    
    ${minTablet} {
      margin-top: ${desktopVW(15)};
    }
  }

  ${minTablet} {
    margin-top: ${desktopVW(30)};
  }
`;

const Background = styled.div`
  background: ${color.greyLight};
  padding: ${mobileVW(15)};

  ${minTablet} {
    padding: ${desktopVW(60)};
  }
`;

export default VergetenPage;
